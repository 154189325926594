import React, { CSSProperties } from "react";
import styles from "./Tooltip.module.scss";

type Props = {
    description: String;
    backgroundColor: CSSProperties;
    textColor: CSSProperties;
}

type State = {
    hovering: boolean;
}

class Tooltip extends React.Component<Props, State>{
    state: State = { hovering: false }

    handleHover = (e: React.MouseEvent<HTMLSpanElement>) => {
        this.setState({ hovering: !this.state.hovering })
    }
    handleFocus = (e: React.FocusEvent<HTMLSpanElement>) =>{
        this.setState({ hovering: true})
    }
    handleUnFocus = (e: React.FocusEvent<HTMLSpanElement>) =>{
        this.setState({ hovering: false})
    }
    handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) =>{
        if(e.key === "Escape"){
            this.setState({ hovering: false})
        }
      };
   

    render() {
        const { description, backgroundColor, textColor } = this.props;
        return (
            <React.Fragment>
                <span className={styles.tooltipwrapper} >
                    <button aria-label="åpne hjelpetekst" className={styles.tooltipicon} onKeyDown={this.handleKeyDown} onFocus={this.handleFocus} onBlur={this.handleUnFocus} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>{"?"}</button>
                    {this.state.hovering ?
                        (
                            <div style={backgroundColor} className={styles.tooltipcontainer}>
                                <p style={textColor}>{description}</p>
                            </div>
                        )
                        : (null)
                    }
                </span>
            </React.Fragment>
        )
    }
}

export default Tooltip;
