import React, { Component } from "react";
import OpenIcon from "../Generic/OpenIcon";
import CloseIcon2 from "../Generic/CloseIcon2";
import Translate from "../../app/Translate";
import styles from "./LonnSpecificChoice.module.scss";
import RadioButtonGroup from "../../defaultComponents/RadioButtonGroup";
import ClickOutsideListener from "../../utils/ClickOutsideListner";

const nusData: any[] = require("../../../data/ssb_nus_12407.json");

type Props = {
  onChange: (uno_id: string, ssb_sektor: string) => void;
  data: any;
  selectedChoice: string;
  unoId: string;
  page: string;
};

type State = {
  openModal: boolean;
};

class LonnSpecificChoice extends Component<Props, State> {
  state = { openModal: false };

  toggleModal = () => {
    if (Object.keys(this.props.data).length <= 1) return;
    this.setState({ openModal: !this.state.openModal });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ssb_sektor = event.currentTarget.id;
    const uno_id = this.props.unoId;

    this.props.onChange(this.props.unoId, ssb_sektor);
  };

  render() {
    const { data, selectedChoice, unoId, page } = this.props;

    // A function for getting realted nus data
    const nusFunc = () => {
      const getNusData: any[] = [];
      const nusDataArray: any[] = [];
      // Save nus data inside getNusdata
      for (const key in data) {
        const value: any = key;
        getNusData.push(data[value]["A"]["A"]["A"]["A"]["nus"]);
      }
      // check the similar nus data from "../../../data/ssb_nus_12407.json" by our getNusData variable.
      getNusData.map((nus) => {
        nusData.filter((item) => {
          if (item["nus4_kode"] === nus[0]) {
            nusDataArray.push(item["nus4_navn"]);
          }
        });
      });
      // filter nusDataArray variable by this function to get unique values.
      function onlyUnique(value: number, index: number, self: any) {
        return self.indexOf(value) === index;
      }
      const nusFinal: any[] = nusDataArray.filter(onlyUnique);

      return nusFinal;
    };

    const styrk08_json = sessionStorage.getItem(`styrk08_${unoId}`) || "[]";
    const styrk08_list = JSON.parse(styrk08_json);
    // for utdanning page we use nusFunc function to get similar nus data
    const labels = page === "utdanning" ? nusFunc() : [];
    for (const styrk08 of styrk08_list) {
      for (const key in data) {
        const value: any = data[key];
        const current = value.A.A.A.A.styrk08[0];
        if (styrk08 === current) {
          labels.push(key);
        }
      }
    }
    const OpenIconDom = labels.length > 1 ? <OpenIcon /> : null;

    const Modal = this.state.openModal ? (
      <div className={`${styles.modal}`}>
        <div className={`${styles.modal_header}`} onClick={this.toggleModal}>
          <div className={`${styles.modal_header_text}`}>
            <div className={`${styles.modal_header_text_header}`}>
              <Translate nb="Viser tall for:" />
            </div>
          </div>
          <div className={`${styles.modal_header_icon}`}>
            <CloseIcon2 />
          </div>
        </div>
        <div className={`${styles.modal_header_text_content}`}>
          <RadioButtonGroup
            group={labels.map((k) => {
              return {
                text: k,
                selected: selectedChoice === k,
                valueKey: k,
              } as any;
            })}
            onChange={this.handleChange}
          />
        </div>
      </div>
    ) : null;
    return (
      <ClickOutsideListener onOutsideClick={this.closeModal}>
        <div className={`${styles.container}`}>
          <div
            className={`${styles.box}`}
            onClick={this.toggleModal}
            style={{
              cursor: `${labels.length > 1 ? "pointer" : "auto"}`,
            }}
          >
            <div className={`${styles.box_text}`}>
              <div className={`${styles.box_text_header}`}>
                <Translate nb="Viser tall for:" />
              </div>
              <div>{selectedChoice}</div>
            </div>
            <div className={`${styles.box_icon}`}>{OpenIconDom}</div>
          </div>
          {Modal}
        </div>
      </ClickOutsideListener>
    );
  }
}

export default LonnSpecificChoice;
