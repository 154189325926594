import { getMain } from "../data/main";

export default function UnoIdValidator(urlHash: Array<string>) {      // Returns a urlhash with only valid unoids
    getMain(data => {
        for (let i = 0; i < urlHash.length; i++) {
            if (!data[urlHash[i]]) {
                urlHash.splice(i, 1)
            }
        }
    })
    return urlHash;
}