import React, { Component } from "react";
import { Link, RouteComponentProps, Redirect } from "react-router-dom";

import styles from "./ResetButton.module.scss";

import Translate from "../app/Translate";
import { ReactComponent as ArrowDown } from "../../fontawesome/solid/angle-down.svg";
import { ReactComponent as Times } from "../../fontawesome/solid/times.svg";
import { MIN_DESKTOP_PX } from "../../util/Constants";
import { active } from "d3";
import { with_app_state, AppStateProps } from "../app/AppContext";
import { DataList, MainElement, Innholdstype } from "../../data/ApiTypes";

import { ReactComponent as Icon } from "../ui/SearchIcon.svg";
import CloseIcon2 from "../visualizations/Generic/CloseIcon2";

type Props = {
    selected_uno_id: string[];
    toggleuno: Function;
    resetInterests: Function;
    resetNiva: Function;
}


export default class InnHoldButton extends Component<Props> {

    handleClick = () => {
        for (let i = 0; i < this.props.selected_uno_id.length; i++) {
            this.props.toggleuno(this.props.selected_uno_id[i]);
        }
        this.props.resetInterests();
        this.props.resetNiva();
    }

    render() {
        return (
          this.props.selected_uno_id.length ? <div className={`${styles.container}`}>
                <button aria-label="Nullstill" className={`${styles.container_button}`} onClick={this.handleClick}>
                    <span>
                        <Translate nb="Nullstill" />
                    </span>
                    {/*<span>
                        <Times />
                    </span>*/}
                </button>
            </div> : null
        )
    }
}
