import { Arbeidstid, Sektor, Kjønn } from "../../../data/ApiTypes";
import React, { Component } from "react";
import Translate from "../../app/Translate";
import styles from "../Shared/VisualizationFilterHeader.module.scss";
import HeaderLonnFilters from "./HeaderLonnFilters";
import ClickOutsideListener from "../../utils/ClickOutsideListner";
import CloseIcon2 from "../Generic/CloseIcon2";

export type VisualizationHeaderConfigLonn = {
  Arbeidstid: Arbeidstid;
  Sektor: Sektor;
  Tidsenhet: Tidsenhet;
  Lønn: Lønn;
  StatistiskMål: StatistiskMål;
  Kjønn: Kjønn;
  ssbSektor: { [uno_id: string]: string };
  widgetShowMobileMenu: boolean;
  lonnDivRef: any;
  date?: string | null;
  source?: string;
};

export type Tidsenhet = "Årlig" | "Månedlig" | "Ca. timelønn";
export type Lønn = "Brutto" | "Med overtid";
export type StatistiskMål = "Median" | "Gjennomsnitt" | "Median og kvartiler";

type Props = {
  widget: boolean;
  config: VisualizationHeaderConfigLonn;
  setConfig: (config: VisualizationHeaderConfigLonn) => void;
  onFilterClicked: (event: any, key: string) => void;
  date?: string | null;
  source?: string;
};

type State = {
  open: boolean;
};
const StatistiskMålTranslations = {
  Median: <Translate nb="Median" />,
  Gjennomsnitt: <Translate nb="Gjennomsnittlig" />,
  'Median og kvartiler': <Translate nb="Median (og kvartil)" />,
};
const LonnTranslations = {
  Brutto: <Translate nb=" " />,
  "Med overtid": <Translate nb="med overtid" />,
};
const TidsenhetTranslations = {
  Årlig: <Translate nb="Årslønn" />,
  Månedlig: <Translate nb="Månedslønn" />,
  "Ca. timelønn": <Translate nb="Ca. timelønn" />,
};

class VisualizationHeaderLonn extends Component<Props, State> {
  state = { open: false };

  onFilterButtonClick = (open: boolean) => {
    this.setState({ open: open });
  };
  closeFilter = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      config: { Arbeidstid, Sektor, Tidsenhet, Lønn, StatistiskMål, Kjønn, date },
      widget,
    } = this.props;
    let Modal = null;
    if (!this.props.config.Arbeidstid) return null;
    if (this.state.open)
      Modal = (
        <ClickOutsideListener
          onOutsideClick={this.closeFilter}
          className={`${styles.visualizationheader_container_modal}`}
        >
          <div
            className={`${styles.visualizationheader_container_modal_header}`}
            onClick={() => this.onFilterButtonClick(false)}
          >
            <div
              className={`${styles.visualizationheader_container_modal_header_title
                }`}
            >
              {/* <Translate nb="Lønn" /> -{" "} */}
              <span
                className={`${styles.visualizationheader_container_modal_header_title__desc
                  }`}
              >
                <Translate nb="Skjul visningsalternativer" />
              </span>
              <div
                className={`${styles.visualizationheader_container_modal_header_icon
                  }`}
              // onClick={() => this.onFilterButtonClick(false)}
              />
            </div>
          </div>
          <HeaderLonnFilters
            config={this.props.config}
            onFilterClicked={this.props.onFilterClicked}
            showHelpText={true}
            showHeaders={true}
            showHeaderHelpText={true}
          />
        </ClickOutsideListener>
      );

    return (
      <div>
        <div
          className={`${styles.visualizationheader_container} ${widget ? styles.visualizationheader_container_widget : ""
            }`}
        >
          <div className={`${styles.visualizationheader_container_header}`} />
          <div
            className={`${styles.visualizationheader_container_header__title} ${widget
              ? styles.visualizationheader_container_header__title_widget
              : ""
              }`}
          >
            {widget ? (
              <p>
                <Translate nb="Endre visning" />
              </p>
            ) : (
              <h2>
                <Translate nb="Lønn" />{" "}
              </h2>
            )}
            {widget ? (
              ""
            ) : (
              <span
                className={`${styles.visualizationheader_container_header__title_filter
                  }`}
              >
               
                {StatistiskMålTranslations[StatistiskMål]}
                {" "} 
                {TidsenhetTranslations[Tidsenhet]} 
                {" "}
                {LonnTranslations[Lønn] }
                  {" for "}
                 {Arbeidstid === "A" ? (
                      
                        <Translate nb="Både hel- og deltidsansatte " />
                      
                    ) : Arbeidstid === "D" ? (
                        <Translate nb="deltidsansatte " />
                    ) : (
                        <Translate nb="heltidsansatte " />
                    )}
                    {Sektor === "A" ? (
                      <Translate nb="i alle sektorer" />
                    ) : Sektor === "K" ? (
                      <Translate nb="i kommunal sektor" />
                    ) : Sektor === "P" ? (
                      <Translate nb="i privat sektor" />
                    ) : (
                      <Translate nb="i staten" />
                    )}
                    {", "}
                    {Kjønn === "A" ? (
                      <Translate nb="Begge kjønn" />
                    ) : (
                        <Translate nb="henholdsvis kvinner og menn" />
                    )}
                    {". "}
                  {this.props.date && this.props.date.length > 0 &&
                      ("Tall fra " + this.props.date +". ")
                  }
                  {this.props.source && this.props.source.length > 0 &&
                    
                      <a href= {'https://ssb.no/tabell/' + this.props.source}  title="lenke til Statistikkbanken, Statistisk sentralbyrå">Kilde: <p className={styles.dataSource}>SSB</p></a>
                    
                  }

                  
                
              </span>
            )}
            {!this.state.open &&
              <div className={styles.visualizationheader_container_content}>
                <div className={styles.visualizationheader_container_content_toggle} onClick={() => this.onFilterButtonClick(true)}>
                  <Translate nb="Visningsalternativer" />
                  <span
                    className={`${styles.visualizationheader_container_header__title_icon
                      } ${widget
                        ? ""
                        : styles.visualizationheader_container_header__title_icon_non_widget
                      }`}
                  >
                    {this.state.open ? <CloseIcon2 /> : "+"}
                  </span>
                </div>
              </div>
            }
          </div>

          {Modal}
        </div>
        {this.props.children}
      </div>
    );
  }
}
export default VisualizationHeaderLonn;
