import React, { Component } from "react";
import {ArbeidsledighetObject} from "../../data/ApiTypes";
import {Fullført, Ledighetsintervall, Visning} from "../visualizations/Arbeidsledighet/ArbeidsledighetWrapper";
import ClickOutsideListener from "../utils/ClickOutsideListner";
import styles from "./ShowingDataFor.module.scss";
import CloseIcon2 from "../visualizations/Generic/CloseIcon2";
import OpenIcon from "../visualizations/Generic/OpenIcon";
import Translate from "../app/Translate";


type Props = {
  data: ArbeidsledighetObject;
  visning: Visning;
  teaser: string;
};


type State = { showMore: boolean };

class ShowingDataFor extends Component<Props, State> {
  state: State = { showMore: false };

  handleClick = (e: any) => {
    this.setState({ showMore: !this.state.showMore });
  }

  closeModal = (e: any) => {
    this.setState({ showMore: false });
  }

  renderNumbersFrom = () => {
    if (!this.props.data.nus_kortnavn) {
      return null;
    }

    let nusNavn = this.props.data.nus_kortnavn.replace(new RegExp(" ;", "g"), "");
    let nusArr = nusNavn.split(',');

    const nusFrom = nusArr.map((nus, index) => {
      return <li key={index}>{nus}</li>
    })
    return nusFrom;
  }

  render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    if (!this.props.data.nus_kortnavn) {
      return null;
    }

    return (
      <ClickOutsideListener onOutsideClick={this.closeModal}>
        <div className={`${styles.showingDataFor_wrapper}`}>
          <div className={`${styles.showingDataFor} ${this.state.showMore ? styles.expanded : ''}`}>
            {/*<p onClick={this.handleClick}>Viser tall for <GreaterThan className={iconClass} /></p>*/}
            <div className={`${styles.showingDataFor_header}`} onClick={this.handleClick}>
              <Translate nb="Viser tall for" />
              <div className={`${styles.showingDataFor_toggler}`}>
                {this.state.showMore ? <CloseIcon2 /> : <OpenIcon />}
              </div>
            </div>
            {this.state.showMore &&
            <React.Fragment>
              <div className={`${styles.showingDataFor_content}`}>
                <p><Translate nb={this.props.teaser} /></p>
                <ul>
                  {this.renderNumbersFrom()}
                </ul>
              </div>
            </React.Fragment>
            }
          </div>
        </div>
      </ClickOutsideListener>
    );
  }
}

export default ShowingDataFor;
