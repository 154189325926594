import React, { Component } from "react";
import styles from "./WidgetSeparator.module.scss";

type Props = {
}


class WidgetSeparator extends Component<Props>{
    render() {
        return (
            <div className={styles.separator}></div>
        )
    }
}

export default WidgetSeparator;
