import React, { Component } from "react";
import { Link } from "react-router-dom";

import styles from "./Breadcrumb.module.scss";

import { Innholdstype } from "../../../data/ApiTypes";
import Translate from "../../app/Translate";
import SearchBox from "../../ui/SearchBox";
import Button from "../../ui/Button";
import { num_compare_sizing } from "../../utils/NumCompareSizing";
import { MIN_DESKTOP_PX } from "../../../util/Constants";
import { getUrlState } from "../../../util/urlState";

type Props = {
  innholdstype: Innholdstype;
};

function checkUrl(code: string, url: string[]) {
  let result: boolean = false;
  result = url.some(uno_id => uno_id.substr(0, 1) === code);
  return result;
}

class Breadcrumb extends Component<Props> {
  render() {
    const { innholdstype } = this.props;

    const urlState = getUrlState();
    let hasYrke = checkUrl("y", urlState);
    let hasUtdanning = checkUrl("u", urlState);

    switch (innholdstype) {
      case "yrke":
        return (
          <div className={`${styles.breadcrumb}`}>
            <SearchBox
              innholdstype={innholdstype}
              className={`${styles.SearchBox}`}
              focusOnMount={innerWidth >= MIN_DESKTOP_PX}
            />
            <div className={`${styles.breadcrumb_mobilecontainer}`}>
              <Link to={"/liste/yrke"}>
                <Translate nb="Se alle yrker" />
              </Link>
            </div>
            <div className={`${styles.breadcrumb_mobilecontainer}`}>
              <Link to={hasUtdanning ? "/sammenligne/utdanning" : "/liste/utdanning"} type="light">
                <Translate nb="Bytt til utdanninger" />
              </Link>
            </div>
          </div>
        );
      case "utdanning":
        return (
          <div className={`${styles.breadcrumb}`}>
            <SearchBox
              innholdstype={innholdstype}
              className={`${styles.SearchBox}`}
              focusOnMount={innerWidth >= MIN_DESKTOP_PX}
            />
            <div className={`${styles.breadcrumb_mobilecontainer}`}>
              <Link to={"/liste/utdanning"}>
                <Translate nb="Se alle utdanninger" />
              </Link>
            </div>
            <div className={`${styles.breadcrumb_mobilecontainer}`}>
              <Link to={hasYrke ? "/sammenligne/yrke" : "/liste/yrke"} type="light">
                <Translate nb="Bytt til yrker" />
              </Link>
            </div>
          </div>
        );
    }
  }
}
export default num_compare_sizing<Props>(Breadcrumb);
