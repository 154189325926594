import React, { Component } from "react";
import { ComparisonComponentProps } from "../../comparisonsConfig";
import { SektorElement } from "../../../data/ApiTypes";
import NoData from "../Old/NoData";
import ComparisonRow from "../../pages/ComparisonPage/ComparisonRow";
import SektorVisualization from "./SektorVisualization";
import Header from "../Shared/HeaderVisualizations";
import Translate from "../../app/Translate";


export type SektorHeaderConfig = {
};


class SektorWrapper extends Component<ComparisonComponentProps<SektorElement>, SektorHeaderConfig>{
    constructor(props: ComparisonComponentProps<SektorElement>) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { data, uno_ids } = this.props;
        const kategorier = ["privat", "offentlig"];
        if (!data || Object.keys(data).length === 0) return <NoData />
        return (
            <div>
                <Header
                    mainHeader={<Translate nb="Sektorer" />}
                    secondHeader={
                        <Translate nb="" />
                    }
                />
                <ComparisonRow>
                    {uno_ids.map(uno_id => {
                        const d = data[uno_id];
                        if (!d) return <NoData key={uno_id} />;
                        const code = Object.keys(d)[0];
                        if (!code) return <NoData key={uno_id} />;
                        return (
                            <SektorVisualization
                                key={uno_id}
                                data={d}
                                kategorier={kategorier}
                            />
                        );
                    })}
                </ComparisonRow>
            </div>
        )
    }
}

export default SektorWrapper;