import React, { Component } from "react";
import { SektorObject } from "../../../data/ApiTypes";
import NoData from "../Old/NoData";
import visualizationstyles from "../Visualization.module.scss";
import PercentageBar from "../Generic/PercentageBar";
import styles from "./SektorVisualization.module.scss";
import Translate from "../../app/Translate";
import Tooltip from "../../ui/Tooltip";

type Props = {
    data: SektorObject;
    kategorier: string[];
};

class SektorVisualization extends Component<Props> {
    getDataQuery = (kategori: string) => {
        let qry = "sektor_antall";
        let offentlig = qry + "_offentlig";
        let privat = qry + "_privat";
        let selvstendig = qry + "_selvstendig";
        let antall = qry + "_personer";
        let keys = [privat, offentlig, selvstendig];
        let cutOff = 25;
        let num, divide, divideby;

        if (keys.every(k => (this.props.data as any)[k] === undefined)) return null;
        if ((this.props.data as any)[antall] === undefined || (this.props.data as any)[antall] < cutOff) return null;

        if (kategori === "privat") {
            // Check if undefined
            keys.forEach(k => {
                if ((this.props.data as any)[k] === undefined) {
                    (this.props.data as any)[k] = 0;
                }
            })
            divide = ((this.props.data as any)[privat] + (this.props.data as any)[selvstendig]);
            divideby = ((this.props.data as any)[offentlig] + (this.props.data as any)[privat] + (this.props.data as any)[selvstendig]);

            if (divideby === 0) {
                // num = 0;
                // return num;
            }

            num = (divide / divideby) * 100;
        } else if (kategori === "offentlig") {
            keys.forEach(k => {
                if ((this.props.data as any)[k] === undefined) {
                    (this.props.data as any)[k] = 0;
                }
            })

            divide = (this.props.data as any)[offentlig];
            divideby = ((this.props.data as any)[offentlig] + (this.props.data as any)[privat] + (this.props.data as any)[selvstendig]);

            if (divideby === 0) {
                // division by zero
            }
            num = (divide / divideby) * 100;
        }
        if (!num) return null;

        return num;
    };

    render() {
        const { kategorier } = this.props;
        let offentligDescription = "Hvor stor del jobber i stat, fylke eller kommune";
        let privatDescription = "Hvor stor del jobber i private selskap, som selvstendig næringsdrivende eller i offentlig eide selskap";
        let dataArr: any[] = [];
        kategorier.map(f => {
            dataArr.push(this.getDataQuery(f));
        });
        if (dataArr.every(d => d === null)) return <NoData />;

        return (
            <div className={`${visualizationstyles.visualization_container}`}>
                <div className={styles.container}>
                    {kategorier.map((f, index) => {
                        return (
                            <div key={f}>
                                <div className={styles.container_header}>
                                    {f === "privat" ? (
                                        <Translate nb="Privat" />
                                    ) : f === "offentlig" ? (
                                        <Translate nb="Offentlig" />
                                    ) : (
                                                null
                                            )
                                    }
                                    {f === "privat" &&
                                        <Tooltip description={privatDescription} backgroundColor={{ backgroundColor: "#333" }} textColor={{ color: "white" }} />
                                    }
                                    {f === "offentlig" &&
                                        <Tooltip description={offentligDescription} backgroundColor={{ backgroundColor: "#333" }} textColor={{ color: "white" }} />
                                    }
                                </div>
                                {!dataArr[index] &&
                                    <div className={styles.container_noData}>
                                        <Translate nb="Ingen data" />
                                    </div>
                                }
                                {dataArr[index] &&
                                    <div className={styles.container_andel} style={{ marginBottom: `${f === "710" ? 0 : 20}px` }}>
                                        <PercentageBar value={dataArr[index]} maxPercentageEqualsTen={false} />
                                    </div>
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default SektorVisualization;