import React from "react";
const jmv = require('job-market-visuals');
const { Context3 } = jmv;
import ShowingDataFor from "./ShowingDataFor";
import { ArbeidsledighetObject } from "../../data/ApiTypes";

// const disaggregationValues = ["antall_kvinner", "antall_menn", "antall_ukjent_kjonn"];
// const disaggregationLabels = ["kvinner", "menn", "ukjent kjonn"];

let disaggregationLabels: string[];
let labelExperience = ["personer som har fullført utdanningen for 1-3 år siden", "personer som har fullført utdanningen for 4-10 år siden", "personer som har fullført utdanningen over 10 år siden"];
let labelGender = ["Kvinner", "Menn", "Ukjent kjonn"];
let label40 = ["personer yngre enn 40 år", "personer eldre enn 40 år"];
let labelSector = ["jobber i offentlig sektor", "jobber i privat sektor", "personer"];

const ComparisonPageVisualization = (props: any) => {
    const { uno_id, direction, layout, disaggregate, nus_kortnavn } = props;

    let dis = disaggregate;
    if (disaggregate) {
        switch (disaggregate[disaggregate.length - 1]) {
            case "experience":
                disaggregationLabels = labelExperience;
                break;
            case "40":
                disaggregationLabels = label40;
                break;
            case "sector":
                disaggregationLabels = labelSector;
                break;
            default:
                disaggregationLabels = labelGender;
        }
        dis = disaggregate.slice(0, disaggregate.length - 1);
    }

    const test: ArbeidsledighetObject = {
      nus_kortnavn
    };

    return (
        <React.Fragment>
            <Context3
                id={uno_id}
                limit={8}
                layout={layout}
                disaggregateBy={dis}
                disaggregateLabels={disaggregationLabels}
                direction={direction}
                moreLabel={direction === 'uno_id2styrk08' ? ' andre yrker' : ' andre utdanninger'}
                colors={{
                    text: "#333",
                    textTree: "#333",
                    primary: "#ff9800",
                    disaggregations: ["#84012e", "#267CC1", "#ddd"],
                    notWorking: "#ff5722",
                    unemployed: "#f44336",
                    inEducation: "#ff9800",
                    selfEmployed: "#ff9800",
                    other: "#9e9e9e",
                }}
                api_url={(window as any).CONFIG_API_BASE_URL}
            />
          <ShowingDataFor
            data={test}
            teaser="Viser hvilke yrker de som har fullført følgende utdanninger har:"
            visning={props.visning}
          />
        </React.Fragment>
    )
}

export default ComparisonPageVisualization;
