import React, { Component } from "react";
import Translate from "../../app/Translate";
import styles from "../Shared/HeaderFilterDesktop.module.scss";
import OpenIcon from "../Generic/OpenIcon";
import CloseIcon2 from "../Generic/CloseIcon2";
import { UtdanningLonnConfig } from "./UtdanningLonnConfig";
import ClickOutsideListener from "../../utils/ClickOutsideListner";
import UtdanningLonnHeaderFilters from "./UtdanningLonnHeaderFilters";

type Props = {
  config: UtdanningLonnConfig;
  onFilterClicked: (event: any, key: string) => void;
};

type State = {
  expanded: boolean;
};

class UtdanningLonnHeaderDesktop extends Component<Props, State> {
  state = { expanded: false };

  toggleExpansion = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  closeExpansion = () => {
    this.setState({ expanded: false });
  };

  render() {
    const { config, onFilterClicked } = this.props;
    const containerContent = (
      <div className={`${styles.container_content}`}>
        <UtdanningLonnHeaderFilters
          config={config}
          onFilterClicked={onFilterClicked}
          showHelpText={true}
          showHeaders={true}
          showHeaderHelpText={true}
        />
      </div>
    );

    return (
      <ClickOutsideListener
        onOutsideClick={this.closeExpansion}
        className={`${styles.container} ${this.state.expanded ? styles.expanded : ''}`}
      >
        <div
          className={`${styles.container_filtercontainer}`}
        >
          <button
            aria-label="åpne/lukk"
            className={`${styles.container_filtercontainer_content} ${this.state.expanded ? styles.container_filtercontainer_content_expanded : ""}`}
            onClick={this.toggleExpansion}
          >
            <div className={`${styles.container_filtercontainer_content_head_infotext}`}>
              <Translate nb={this.state.expanded ? "Skjul visningsalternativer" : "Visningsalternativer"} />
            </div>
            <div className={`${styles.container_filtercontainer_content_head_icon}`}>
              {this.state.expanded ? <CloseIcon2 /> : "+"}
            </div>
          </button>
        </div>
        {this.state.expanded ? containerContent : null}
      </ClickOutsideListener>
    );
  }
}

export default UtdanningLonnHeaderDesktop;
